'use client';

// External
import React from 'react';

import { useAtomValue } from 'jotai';

import Grid from '@taskrabbit/meadow-web/lib/Grid';
import Stack from '@taskrabbit/meadow-web/lib/Stack';
import Typography from '@taskrabbit/meadow-web/lib/Typography';

// Internal
import decisionAtom from '~jotai/atoms/decision';
import type { DecisionAtom } from '~jotai/atoms/decision';

export interface DevInfoProps {
  branch?: string;
  commit?: string;
  enabled: boolean;
}

interface BranchAndCommitProps {
  branch?: string;
  commit?: string;
}

// Helper component
const BranchAndCommit = ({ branch, commit }: BranchAndCommitProps) => (
  <Stack spacing={1}>
    <Typography fontWeight="bold">Branch Name:</Typography>
    <Typography variant="body3">{branch}</Typography>
    <Typography fontWeight="bold">Commit:</Typography>
    <Typography variant="body3">{commit}</Typography>
  </Stack>
);

const EnabledFeatureFlags = ({ features }: DecisionAtom) => (
  <Stack spacing={1}>
    <Typography fontWeight="bold">Enabled Features:</Typography>
    {features.map((feature) => (
      <Typography key={feature} variant="body3">
        {feature}
      </Typography>
    ))}
  </Stack>
);

const Experiments = ({ experiments }: DecisionAtom) => (
  <Stack spacing={1}>
    <Typography fontWeight="bold">Enabled Experiments:</Typography>
    {experiments.map((experiment) => (
      <Typography key={experiment.experiment_key} variant="body3">
        {experiment.experiment_key}:{experiment.variation_key}
      </Typography>
    ))}
  </Stack>
);

// Helpers
export const getDevInfoProps = (): DevInfoProps => ({
  branch: process.env.NEXT_PUBLIC_TR_WEB_BRANCH,
  commit: process.env.NEXT_PUBLIC_TR_WEB_SHA,
  enabled: process.env.NEXT_PUBLIC_TR_WEB_DEBUG === '1',
});

// Component
const DevInfo = ({ branch, commit, enabled }: DevInfoProps) => {
  const { experiments, features } = useAtomValue(decisionAtom);

  if (!enabled) {
    return null;
  }

  return (
    <Grid container padding={2}>
      <Grid item md={4} xs={12}>
        <BranchAndCommit branch={branch} commit={commit} />
      </Grid>
      <Grid item md={4} xs={12}>
        <EnabledFeatureFlags experiments={experiments} features={features} />
      </Grid>
      <Grid item md={4} xs={12}>
        <Experiments experiments={experiments} features={features} />
      </Grid>
    </Grid>
  );
};

export default DevInfo;
